/* You can add global styles to this file, and also import other style files */

/* === Plugin styles === */

@import "~react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
@import "~@fullcalendar/core/main.css";
@import "~@fullcalendar/daygrid/main.css";

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

@import "../assets/styles/variables";
@import "~compass-mixins/lib/compass";
@import "~compass-mixins/lib/animate";
@import "~bootstrap/scss/bootstrap";
@import "../assets/styles/fonts";
@import "../assets/styles/functions";

@import url("https://fonts.googleapis.com/css2?family=Birthstone&family=Roboto:wght@300&family=Yaldevi:wght@200&display=swap");
/* === Icon fonts === */
@import "~@mdi/font/scss/materialdesignicons";
// @import "~flag-icon-css/sass/flag-icon";

/* === Template mixins === */
@import "../assets/styles/mixins/animation";
@import "../assets/styles/mixins/badges";
@import "../assets/styles/mixins/buttons";
@import "../assets/styles/mixins/misc";
@import "../assets/styles/mixins/color-functions";
@import "../assets/styles/mixins/cards";
@import "../assets/styles/mixins/blockqoute";
@import "../assets/styles/mixins/popovers";
@import "../assets/styles/mixins/tooltips";
@import "../assets/styles/mixins/no-ui-slider";

/* === Core Styles === */
@import "../assets/styles/background";
@import "../assets/styles/typography";
@import "../assets/styles/reset";
@import "../assets/styles/responsive";
@import "../assets/styles/misc";
@import "../assets/styles/utilities";
@import "../assets/styles/demo";
@import "../assets/styles/spinner";
@import "../assets/styles/dashboard";

/* === Components === */

@import "../assets/styles/components/forms";
@import "../assets/styles/components/checkbox-radio";
@import "../assets/styles/components/icons";
@import "../assets/styles/components/tables";
@import "../assets/styles/components/buttons";
@import "../assets/styles/components/breadcrumbs";
@import "../assets/styles/components/cards";
@import "../assets/styles/components/preview";
@import "../assets/styles/components/user-profile";
@import "../assets/styles/components/lists";
@import "../assets/styles/components/bootstrap-progress";
@import "../assets/styles/components/tabs";
@import "../assets/styles/components/dropdowns";
@import "../assets/styles/components/pagination";
// @import "../assets/styles/components/loaders/loaders";
@import "../assets/styles/components/timeline";
@import "../assets/styles/components/spinner";
@import "../assets/styles/components/react-table";

@import "../assets/styles/components/landing-screens/auth";

/* === Plugin Overrides === */
@import "../assets/styles/components/plugin-overrides/react-bootstrap-table";
@import "../assets/styles/components/plugin-overrides/react-table";

/* === Layout === */
@import "../assets/styles/navbar";
@import "../assets/styles/sidebar";
@import "../assets/styles/footer";
@import "../assets/styles/layout";
@import "../assets/styles/settings-panel";

.sc-dlfnuX.gXlmGk.rdt_TableHeadRow {
  background-color: #1c45ef;
  color: white;
  border-radius: 8px;
}
body {
  font-family: "Roboto", sans-serif;
  font-size: 100px;
}
* {
  font-size: 14px;
}

// .main-panel {

//     margin-left: 13% !important;

// }

// .sidebar {

//     position: fixed !important;

// }

@media only screen and (max-width: 600px) {
}

@media only screen and (max-width: 1920px) and (min-width: 992px) {
  .sidebar {
    position: fixed;
    left: 0;
    width: 270px;
    height: 100vh;
    overflow: scroll;
    // background: linear-gradient(to top, #f78da7,  #ffe6f4);
  }
}

a:hover {
  // color: hotpink;
  text-decoration: none;
}

.sidebar {
  color: pink !important;
  background: #ffe6f4;
}

.nav-link {
  color: #f85476 !important;
}

.rdt_TableHeadRow {
  background-color: #f85476 !important;
}

.sidebar > .nav .nav-item .nav-link:hover {
  background-color: #f85476 !important;
  color: #fff;
  .MuiSvgIcon-root {
    color: #fff;
  }
}
.sidebar > .nav .nav-item.active {
  background-color: #f85476 !important;
  color: #fff;
  .MuiSvgIcon-root {
    color: #fff;
  }
  .nav-link {
    color: #fff !important;
  }
}

.main-panel {
  margin-left: auto !important;
  background: #ffe6f4;
}

.sc-hHfuMS.dBiTke.rdt_TableBody {
  margin: 5px !important
;
}

span.MuiButton-label {
  font-size: 10px;
  font-weight: 900;
}
.interaction-action {
  flex-direction: row;
}
.interaction-action {
  > div {
    margin-right: 3rem;
    &:hover {
      color: #f85476;
    }
  }
}

.likes-modal-list li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 0;
  & + li {
    // margin-top: 10px;
    border-top: 2px solid #ededed;
  }
  &::after {
    content: "";
    border: solid #666;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 2px;
    margin-left: auto;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }
}
.likes-modal-list li img {
  width: 35px;
  height: 35px;
  margin-right: 1rem;
}

.pagination-btns {
  border-radius: 50%;
  font-weight: 500;
  color: #4e4e4e;
  background: none;
  border: none;
   * {
      padding: 8px;
      margin: 0 6px;
      cursor:pointer;
      &:hover{
          opacity: .7;
      }
   }
   &:first-child,
   &:last-child {
      font-size: 18px;
   }
}
.pagination-btns .active {
  color: #F85476;
}

.post_card {
  background-color: #fff;
  border-radius: 10px;
  padding: 1rem;
}
.post_card .post_card_header {
  display: flex;
  justify-content: space-between; /* Changed to space-between */
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.post_card .post_card_header img {
  width: 35px;
  height: 35px;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.post_card .post_card_header p {
  margin: 0 !important;
}

.post_card .post_media {
  display: flex;
  gap: 10px;
  flex-direction: column;
}
.post_card .post_card_content p {
  margin-top: 1rem;
}
.post_card .post_media > div {
  max-width: 300px;
}
.post_card .post_hashtags {
  margin-top: 1rem;
}
.post_card .post_hashtags span {
  color: #F85476;
  font-weight: 500;
  margin-right: 8px;
}

.reply-comment {
  margin-left: 20px; /* Indent replies for hierarchy */
  border-left: 2px solid #ccc; /* Left border to indicate a reply thread */
  padding-left: 10px; /* Padding from the left border */
}

.reply-comment img {
  width: 30px; /* Smaller image for reply comments */
  height: 30px;
  border-radius: 15px; /* Circular images for profile pics */
}

.reply-comment div {
  margin-bottom: 10px; /* Space between comments */
}

.reply-comment span {
  font-size: 0.9em; /* Slightly smaller text for reply author */
  color: #555; /* Dimmed color for author name */
}

.reply-comment p {
  background: #f9f9f9; /* Light background for the reply */
  border-radius: 5px; /* Rounded corners for the comment box */
  padding: 5px 10px; /* Padding inside the comment box */
  margin: 0; /* Remove default margins */
  font-size: 0.8em; /* Slightly smaller font size for replies */
}

.like-count {
  color: #888; /* Color for the like count text */
  font-size: 0.8em; /* Smaller font size for the like count */
  margin-top: 5px; /* Space from the comment text */
  display: flex; /* To use flex properties for alignment */
  align-items: center; /* Vertically center the like icon and text */
}

/* You can add an icon before the like count if you have one */
.like-count::before {
  // content: '♡'; /* Path to your like icon image */
  margin-right: 5px; /* Space between the icon and text */
  display: block;
  width: 16px; /* Size of the like icon */
  height: 16px;
}

.pagination-btns span, .pagination-btns i {
  padding: 8px;
  margin: 0 6px;
  cursor: pointer;
  border-radius: 50%;
  font-weight: 500;
  color: #4e4e4e;
  background: none;
  border: none;
}

.pagination-btns span:hover, .pagination-btns i:hover {
  opacity: .7;
}

.pagination-btns i.disabled {
  cursor: default;
  opacity: 0.5;
}

.pagination-btns i:first-child,
.pagination-btns i:last-child {
  font-size: 18px;
}

.pagination-btns .active {
  color: #F85476;
}


@media screen and (max-width: 1440px) {
  .post_card {
    .interaction-action {
      justify-content: space-between;
      > div {
        margin-right: 0;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  .interaction-action {
    justify-content: space-between;
    > div {
      margin-right: 0;
    }
  }
  .post_card {
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 576px) {
  .interaction-action {
  }
}
